<template>
  <div>
    <!-- 按钮 -->
    <el-row style="margin-bottom: 20px">
      <el-button size="medium" @click="quitBtn">返回</el-button>
      <el-button
        size="medium"
        :disabled="!tableData.length"
        @click="arrangementExamBtn"
        type="primary"
        >安排补考</el-button
      >
      <el-button
        size="medium"
        :disabled="!tableData.length"
        @click="downloadListBtn"
        type="primary"
        >下载名单</el-button
      >
    </el-row>
    <!-- 条件列表 -->
    <!-- <el-row>
      <el-form :inline="true" :model="conditionList">
        <el-form-item label="状态">
          <el-select
            size="medium"
            v-model="conditionList.subjectType"
            placeholder="选择状态"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="物理" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学校">
          <el-select
            size="medium"
            v-model="conditionList.subjectType"
            placeholder="选择学校"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="物理" :value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试科目">
          <el-select
            size="medium"
            v-model="conditionList.subjectType"
            placeholder="选择科目"
          >
            <el-option label="全部" :value="0"></el-option>
            <el-option label="物理" :value="1"></el-option>
            <el-option label="生物" :value="2"></el-option>
            <el-option label="化学" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考试名称">
          <el-input size="medium" v-model="conditionList.name"></el-input>
        </el-form-item>
        <el-button v-throttle type="primary" size="medium">查询</el-button>
        <el-button v-throttle size="medium">重置</el-button>
      </el-form>
    </el-row> -->
    <!-- 表格 -->
    <el-row>
      <el-table
        header-align="center"
        border
        :header-cell-style="{ background: '#DFE6EC' }"
        :data="tableData"
        default-expand-all
        v-loading="loadingTable"
      >
        <el-table-column
          align="center"
          prop="studentName"
          label="姓名"
          width="120"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="examineeCode"
          label="考号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="sysOrgSchoolName"
          label="所属学校"
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="subjectType"
          label="科目"
          width="120"
        >
          <template slot-scope="scope">{{
            scope.row.subjectType | subjectFilter
          }}</template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="sysSrgOperationTestName"
          label="考题"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="makeupReason"
          label="补考原因"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="examineeExamStatus"
          label="考生状态"
          width="120"
        >
          <template slot-scope="scope">{{
            scope.row.examineeExamStatus | examineeExamStatusFilter
          }}</template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tableDataPagination.pageIndex"
        :page-sizes="[10, 20, 50, 100, 150, 200]"
        :page-size="tableDataPagination.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableDataPagination.total"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
import {
  downExamMakeupExamineeExcelApi,
  getMakeupExamineeList,
} from '@/api/exam/exam.js'
export default {
  name: 'examRetake',
  data() {
    return {
      // 条件列表
      conditionList: {},
      loadingTable: false,
      // 表格
      tableData: [],
      // 分页
      tableDataPagination: {
        // 当前页
        pageIndex: 1,
        // 页大小
        pageSize: 10,
        // 页数
        pageTotal: 1,
        // 总数量
        total: 0,
      },
      examId: '',
      mainExamId: '',
      parentExamId: '',
    }
  },
  computed: {},
  watch: {},
  filters: {
    //学科过滤
    subjectFilter(val) {
      switch (val) {
        case 1:
          return `物理`
        case 2:
          return `生物`
        case 3:
          return `化学`
      }
    },
    examineeExamStatusFilter(key) {
      switch (key) {
        case 3:
          return `正常`
        case 4:
          return `缺考`
        case 5:
          return `取消考试资格`
        case 6:
          return `补考`
        default:
          return `错误的值${key}`
      }
    },
  },
  created() {
    this.examId = Number(this.$route.query.examId)
    this.mainExamId = Number(this.$route.query.mainExamId)
    this.parentExamId = Number(this.$route.query.parentExamId)
    this.getTableListFunc()
  },
  mounted() {},
  methods: {
    //返回
    quitBtn() {
      console.log('返回')
      // 返回 并刷新
      this.$router.go(-1)
    },
    //安排补考
    arrangementExamBtn() {
      console.log('安排补考', this.mainExamId, this.parentExamId)
      this.$confirm('确认安排补考?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$router.push({
            path: '/exam/examAdd',
            query: {
              mainExamId: this.mainExamId,
              parentExamId: this.parentExamId,
            },
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消',
          })
        })
    },
    //下载名单
    downloadListBtn() {
      console.log('下载名单')
      downExamMakeupExamineeExcelApi(this.mainExamId).then((res) => {
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$imageAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    // //查询
    // inquireBtn() {
    //   console.log('查询')
    // },
    // //重置
    // resetBtn() {
    //   console.log('重置')
    // },
    // // 处理条件列表
    // disposeConditionFunc() {
    //   console.log('处理条件列表')
    //   let data = {}
    // },
    // 获取表格数据
    getTableListFunc() {
      this.loadingTable = true
      console.log('获取表格数据')
      getMakeupExamineeList(
        this.examId,
        this.tableDataPagination.pageIndex,
        this.tableDataPagination.pageSize
      )
        .then((res) => {
          this.loadingTable = false
          if (res.success) {
            this.tableData = res.data
            this.tableDataPagination = {
              pageIndex: res.pageIndex,
              pageSize: res.pageSize,
              pageTotal: res.pageTotal,
              total: res.total,
            }
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((err) => {
          console.log('err', err)
          this.loadingTable = false
        })
    },
    // console.log(`每页 ${val} 条`);
    handleSizeChange(val) {
      this.tableDataPagination.pageSize = val
      this.tableDataPagination.pageIndex = 1
      this.getTableListFunc()
    },
    // console.log(`当前页: ${val}`);
    handleCurrentChange(val) {
      this.tableDataPagination.pageIndex = val
      this.getTableListFunc()
    },
  },
}
</script>

<style lang="scss" scoped></style>
